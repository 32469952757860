<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.applicants') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0" v-for="(applicant, idx) in applicants" :key="`applicant-${idx}`">
        <v-divider v-if="idx !== 0" />
        <v-subheader class="subheading black--text">{{ `${applicant.firstname} ${applicant.lastname}` }}</v-subheader>
        <template v-if="provider !== 'SIMPL'">
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('labels.applicantType') }}</v-list-item-subtitle>
              <v-list-item-title>{{ applicant.type }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t('labels.applicantID') }}</v-list-item-subtitle>
              <v-list-item-title>{{ applicant.applicant_id }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  name: 'VLeadApplicants',
  components: { VWidget },
  props: {
    provider: { type: String, required: true },
    applicants: { type: Array, required: true }
  }
}
</script>

<style scoped lang="scss">
.v-list__tile__action.applicant .v-input--selection-controls {
  margin-top: 16px !important;
}
.subheading {
  background-color: #fafafa;
  font-size: $font-size-root;
}
</style>
