<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12" v-if="lead.broker_id">
          <VFullBroker :broker-id="lead.broker_id" />
        </v-col>
        <v-col cols="12" v-if="lead.provider !== 'SIMPL'">
          <VLeadDealInfo
            v-if="lead.meta.deal_info"
            :info="lead.meta.deal_info"
            :provider="lead.provider"
            :provider-lead-id="lead.provider_lead_id"
          />
        </v-col>
        <v-col cols="12" v-if="lead.provider !== 'SIMPL'">
          <VSubjectProperty :subject="lead.meta.subject_property" v-if="lead.meta.subject_property" />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col cols="12">
          <VLeadApplicants :applicants="lead.meta.applicants" :provider="lead.provider" />
        </v-col>
        <v-col cols="12">
          <VLeadWorkflows :lead="lead" v-if="hasUsableWorkflows" />
        </v-col>
        <v-col cols="12" v-if="lead.provider !== 'SIMPL'">
          <VLeadErrors :lead-id="lead.id" :errors="lead.meta.errors" v-if="!lead.valid" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { VFullBroker } from '../../brokers/components'
import VLeadDealInfo from './VLeadDealInfo'
import VLeadWorkflows from './VLeadWorkflows'
import VLeadApplicants from './VLeadApplicants'
import VLeadErrors from './VLeadErrors'
import { VSubjectProperty } from '../../deals/components/general'

export default {
  name: 'VLeadDetails',
  components: {
    VFullBroker,
    VLeadDealInfo,
    VLeadWorkflows,
    VLeadApplicants,
    VLeadErrors,
    VSubjectProperty
  },
  props: {
    lead: { type: Object, required: true }
  },
  computed: {
    hasUsableWorkflows() {
      return Array.isArray(this.lead.meta.workflows)
    }
  }
}
</script>

<style></style>
