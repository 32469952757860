var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.revisions')))]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","server-items-length":_vm.revisions.length,"headers":_vm.headers,"items":_vm.revisions,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons[item.file.extension] || _vm.$vuetify.icons.values.file)+" ")])]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.file.filename))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.transformDate(item.created_on)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DownloadBtn',{attrs:{"file":item.file}})]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }