<template>
  <v-row>
    <v-col d-flex cols="12">
      <v-row>
        <v-col cols="12">
          <v-card flat class="VWidget main-card">
            <flow-log />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { FlowLog } from '@argon/evo/entities/components'
export default {
  name: 'VLeadTransitions',
  components: { FlowLog }
}
</script>

<style></style>
