<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.revisions') }}</template>
    <template v-slot:content>
      <v-data-table
        hide-default-footer
        hide-default-header
        :server-items-length="revisions.length"
        :headers="headers"
        :items="revisions"
        :loading="loading"
      >
        <template v-slot:item.icon="{ item }">
          <v-icon small>
            {{ $vuetify.icons[item.file.extension] || $vuetify.icons.values.file }}
          </v-icon>
        </template>
        <template v-slot:item.text="{ item }">
          <span class="text-body-1">{{ item.file.filename }}</span>
        </template>
        <template v-slot:item.date="{ item }">
          <span class="grey--text">{{ transformDate(item.created_on) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <DownloadBtn :file="item.file" />
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget, DownloadBtn } from '@argon/app/components'
import { mapActions, mapGetters, mapState } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'VLeadRevisions',
  components: { VWidget, DownloadBtn },
  props: {
    leadId: { type: [String, Number], required: true }
  },
  data: () => ({
    headers: [
      { text: '', value: 'icon' },
      { text: '', value: 'text' },
      { text: '', value: 'date', width: 30 },
      { text: '', value: 'actions', width: 30, align: 'right' }
    ],
    loading: true
  }),
  computed: {
    ...mapState(NAMESPACE, ['revisions']),
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['listRevisions']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL LTS', tz: this.timezone, locale: this.locale }) : ''
    }
  },
  created() {
    this.listRevisions(this.leadId).then(() => {
      this.loading = false
    })
  }
}
</script>
