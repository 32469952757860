<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.workflows') }}</template>
    <template v-slot:header-actions>
      <v-tooltip top transition="fade-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" @click="doPull" :loading="loading">
            <v-icon small v-text="$vuetify.icons.values.download" />
          </v-btn>
        </template>
        <span>{{ $t('labels.pullLead') }}</span>
      </v-tooltip>
      <v-tooltip top transition="fade-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" @click="doRefresh" :loading="loading">
            <v-icon small v-text="$vuetify.icons.values.reset" />
          </v-btn>
        </template>
        <span>{{ $t('labels.refreshLead') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0" v-for="(item, idx) in items" :key="`workflow-${idx}`">
        <v-divider v-if="idx !== 0" />
        <v-list-item class="subheading">
          <v-list-item-content>
            <v-list-item-title class="h6 text-uppercase">
              {{ $t(`labels.${item.workflow.category}`) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="lead.step === 'completed' && !!item.deal">
            <v-btn icon small :to="goToDeal(item.deal)">
              <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="item.workflow.insurer"></v-divider>
        <v-list-item class="py-1" v-if="item.workflow.insurer">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.insurer') }}</v-list-item-subtitle>
            <v-list-item-title>{{ item.workflow.insurer }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="py-1">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.provider') }}</v-list-item-subtitle>
            <v-list-item-title>{{ item.workflow.provider }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="item.workflow.consent"></v-divider>
        <v-list-item class="py-1" v-if="item.workflow.consent">
          <v-list-item-content>
            <v-list-item-title>{{ $t('labels.consent') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="workflow">
            <v-checkbox v-model="item.workflow.consent" readonly></v-checkbox>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="item.workflow.province"></v-divider>
        <v-list-item class="py-1" v-if="item.workflow.province">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.province') }}</v-list-item-subtitle>
            <v-list-item-title>{{ $t(`regions.CA.${item.workflow.province}`) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../store'
import { VWidget } from '@argon/app/components'

export default {
  name: 'VLeadWorkflows',
  components: { VWidget },
  props: {
    lead: { type: Object, required: true }
  },
  data: () => ({ loading: false }),
  computed: {
    ...mapState(NAMESPACE, ['linkedDeals']),
    items() {
      return this.lead.meta.workflows.map((workflow) => {
        let deal = this.linkedDeals.find((x) => x.category === workflow.category)
        return { workflow, deal }
      })
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listLinkedDeals', 'pullLead', 'refreshLead']),
    doPull() {
      this.loading = true
      this.pullLead(this.lead.id).finally(() => {
        this.loading = false
      })
    },
    doRefresh() {
      this.loading = true
      this.refreshLead(this.lead.id).finally(() => {
        this.loading = false
      })
    },
    goToDeal(deal) {
      return {
        path: `/deals/${deal.deal_type}/cards/${deal.id}`
      }
    }
  },
  created() {
    this.listLinkedDeals(this.lead.id)
  }
}
</script>

<style scoped lang="scss">
.v-list__tile__action.workflow .v-input--selection-controls {
  margin-top: 16px !important;
}
.subheading {
  background-color: #fafafa;
  font-size: $font-size-root;
}
</style>
