<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.dealInformation') }}</template>
    <template v-slot:content>
      <v-list class="pa-0 ma-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.providerLeadId') }}</v-list-item-subtitle>
            <v-list-item-title>{{ providerLeadId }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealType') }}</v-list-item-subtitle>
            <v-list-item-title>{{ info.deal_type }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealState') }}</v-list-item-subtitle>
            <v-list-item-title>{{ info.deal_state }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealStatus') }}</v-list-item-subtitle>
            <v-list-item-title>{{ info.deal_status }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.dealPurpose') }}</v-list-item-subtitle>
            <v-list-item-title>{{ info.deal_purpose }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.creationDate') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(info.creation_date) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.lastUpdate') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(info.last_update) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('labels.effectiveDate') }}</v-list-item-subtitle>
            <v-list-item-title>{{ transformDate(info.effective_date) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget } from '@argon/app/components'

export default {
  name: 'VLeadDealInfo',
  components: { VWidget },
  props: {
    provider: { type: String, required: true },
    providerLeadId: { type: String, required: true },
    info: { type: Object, required: true }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale'])
  },
  methods: {
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL', tz: this.timezone, locale: this.locale }) : ''
    }
  }
}
</script>
