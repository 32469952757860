<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.errors') }}</template>
    <template v-slot:header-actions>
      <v-tooltip top transition="fade-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" @click="doPull" :loading="loading">
            <v-icon small v-text="$vuetify.icons.values.download" />
          </v-btn>
        </template>
        <span>{{ $t('labels.pullLead') }}</span>
      </v-tooltip>
      <v-tooltip top transition="fade-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" @click="doRefresh" :loading="loading">
            <v-icon small v-text="$vuetify.icons.values.reset" />
          </v-btn>
        </template>
        <span>{{ $t('labels.refreshLead') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:content>
      <v-list>
        <template v-for="(field, eidx) in errors">
          <v-divider :key="`divider-${eidx}`" v-if="eidx !== 0" />
          <v-list-item :key="`schema-${eidx}`">
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t(`labels.${field.label}`) }}</v-list-item-subtitle>
              <v-list-item-title>
                <div v-for="(error, lidx) in field.errors" :key="`error-${eidx}-${lidx}`">{{ error }}</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { mapActions } from 'vuex'
import { NAMESPACE } from '../store'

export default {
  name: 'VLeadErrors',
  components: { VWidget },
  props: {
    leadId: { type: Number, required: true },
    errors: { type: Array, required: true }
  },
  data: () => ({ loading: false }),
  methods: {
    ...mapActions(NAMESPACE, ['pullLead', 'refreshLead']),
    doPull() {
      this.loading = true
      this.pullLead(this.leadId).finally(() => {
        this.loading = false
      })
    },
    doRefresh() {
      this.loading = true
      this.refreshLead(this.leadId).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
