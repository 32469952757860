<template>
  <v-container fluid grid-list-md v-if="boardItem">
    <AppTitleBar>
      <template v-slot:title>{{ boardItem.entity.display }}</template>
      <template slot="actions">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="dismiss" v-if="board" v-on="on">
              <v-icon small>{{ $vuetify.icons.values.viewBack }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.backTo') }} {{ board ? board.display : '' }}</span>
        </v-tooltip>
        <v-menu bottom left min-width="150" v-if="$entity.isAllowed('update', boardItem.entity)">
          <template #activator="{ on: menu }">
            <v-tooltip top transition="fade-transition">
              <template #activator="{ on: tooltip }">
                <v-btn icon small slot="activator">
                  <v-icon small v-text="$vuetify.icons.values.options" v-on="{ ...tooltip, ...menu }" />
                </v-btn>
              </template>
              <span>{{ $t('labels.actions') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="archive" v-if="$entity.isAllowed('destroy', boardItem.entity)">
              <v-list-item-title>{{ $t('labels.archive') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="destroy" v-if="$entity.isAllowed('destroy', boardItem.entity)">
              <v-list-item-title>{{ $t('labels.delete') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </AppTitleBar>

    <v-row column>
      <v-col cols="12">
        <VWidget :enable-toolbar="false">
          <template v-slot:content>
            <state-stepper :board-item="boardItem" :include="include" />
          </template>
        </VWidget>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="activeTab" slider-color="grey darken-1">
          <v-tab ripple>{{ $t('labels.details') }}</v-tab>
          <v-tab-item class="mt-4">
            <VLeadDetails :lead="boardItem.entity" />
          </v-tab-item>

          <v-tab ripple>{{ $t('labels.revisions') }}</v-tab>
          <v-tab-item class="mt-4">
            <VLeadRevisions :lead-id="boardItem.entity.id" />
          </v-tab-item>

          <v-tab ripple>
            {{ $t('labels.transitions') }}
            <span v-if="hasTransitionWarning">
              <v-icon small class="ml-2">{{ $vuetify.icons.values.warning }}</v-icon>
            </span>
          </v-tab>
          <v-tab-item class="mt-4">
            <VLeadTransitions />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
  <v-loading v-else />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { AppTitleBar, VWidget, VLoading } from '@argon/app/components'
import { CardMixin } from '@argon/evo/entities/mixins'
import { StateStepper } from '@argon/evo/entities/components'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE as BOARD_NAMESPACE } from '@argon/evo/boards/store'
import entityPerimeter from '@argon/evo/entities/perimeters'
import { VLeadDetails, VLeadRevisions, VLeadTransitions } from '../components'

export default {
  name: 'LeadPageWidget',
  mixins: [CardMixin, Modal],
  components: {
    VWidget,
    AppTitleBar,
    StateStepper,
    VLoading,
    VLeadDetails,
    VLeadRevisions,
    VLeadTransitions
  },
  perimeters: [entityPerimeter],
  data: () => ({
    activeTab: null,
    include: 'firm'
  }),
  computed: {
    ...mapState(BOARD_NAMESPACE, ['board'])
  },
  methods: {
    ...mapActions(BOARD_NAMESPACE, ['getBoard'])
  }
}
</script>

<style scoped>
.tools__area {
  min-height: 200px;
}
</style>

<i18n>
{
  "en": {
    "tasks": "@:labels.tasks",
    "notes": "@:labels.notes",
    "lead": "@:labels.lead",
    "applicants": "@:labels.applicants"
  }
}
</i18n>
